import React from 'react';
import styled from 'styled-components/macro';

import { getBreakpoint } from '../util/theme';

const FacebookContainer = styled.div`
  text-align: center;

  margin: 25px 0 0 0;

  flex-basis: 35%;

  @media (min-width: ${getBreakpoint('lg')}) {
    margin: 0;
  }

  .fb-page {
    width: 100% !important;

    span {
      width: 100% !important;
    }
  }
`;

const Facebook = () => {
  return (
    <div>
      <FacebookContainer className="facebook">
        <div
          className="fb-page"
          data-href="https://www.facebook.com/OneTrustHomeLoans"
          data-tabs="timeline"
          data-small-header="true"
          data-height="400px"
          data-width="450px"
          data-adapt-container-width="true"
          data-hide-cover="true"
          data-show-facepile="false"
        >
          <blockquote
            cite="https://www.facebook.com/OneTrustHomeLoans"
            className="fb-xfbml-parse-ignore"
          >
            <a
              href="https://www.facebook.com/OneTrustHomeLoans"
              target="_blank"
              rel="noopener noreferrer"
            >
              OneTrust Home Loans NMLS #46375
            </a>
          </blockquote>
        </div>
      </FacebookContainer>
    </div>
  );
};

export default Facebook;
