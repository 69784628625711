import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// import data
import materials from '../data/materials';

// import helpers
import { getBreakpoint, getColor } from '../util/theme';
import {
  DocumentDuplicateIcon,
  CalendarDaysIcon,
  EnvelopeIcon,
  UserGroupIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';

const MaterialsBody = styled.div`
  margin: 0 0 50px 0;

  .background {
    background-color: #5583A5;
   margin-left: auto;
   margin-right: auto;
   margin-top: 40px;
   
    border-radius: 4px;
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .background:hover {
    background-color: #2d536f;
    transition: 0.3s;
    transform: scale(1.05);
  }


  .icon{
    height: 50px;
    width: 50px;
    color: white;
  }

  .social {
    justify-content: space-between;
    margin 20px 0;
  }
  .branding ul {
    justify-content: space-between;
  }
  
  .branding img {
    height: 60px;
  }

  h2 {
    padding: 25px 0;
    margin: 25px 0;
    color: #2d536f;
  }

  ul {
    list-style-type: none;

    display: flex;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;

    li {
      margin: 15px 0;

      text-align: center;

      width: 100%;

      @media (min-width: ${getBreakpoint('sm')}) {
        width: auto;
      }

      &.divider {
        border-left: 1px solid #cacaca;

        display: none;

        margin: 25px 0 0 0;
        padding: 0 10px;

        height: 80px;
        width: 1px;

        @media (min-width: ${getBreakpoint('md')}) {
          display: block;
        }
      }

      &.drip {
        text-align: center;
        a {
          text-align: center;
          h3 {
            text-align: center;
          }
        }
      }

      a {
        color: ${getColor('black')};
        text-decoration: none;

        &:hover {
          color: ${getColor('accentGreen')};

          img {
            transform: scale(1.05);
          }
        }

        h3 {
          font-family: 'Roboto Condensed', sans-serif;
          font-size: 15px;
          text-align: center;
          text-transform: uppercase;
          width: 70%;
          margin: 0 auto;
          padding: 10px 0;
        }
      }
    }
  }
`;

const userIcon = icon => {
  //create a switch statement to return the correct icon
  switch (icon) {
    case 'Reverse Custom Collateral':
      return <DocumentDuplicateIcon className="icon" />;
      break;
    case 'Email Campaigns':
      return <CalendarDaysIcon className="icon" />;
      break;

    case 'SOCIAL MEDIA COLLATERAL':
      return <EnvelopeIcon className="icon" />;
      break;

    case 'Flyer Generator':
      return <UserGroupIcon className="icon" />;
      break;
    case 'Social Media Wall':
      return <MegaphoneIcon className="icon" />;
      break;

    default:
      return <DocumentDuplicateIcon className="icon" />;
      break;
  }
};

const Materials = () => {
  const drawBrands = brands => {
    return brands.map(brand => {
      const { external, logo, name, url } = brand;
      return external ? null : (
        <li key={name}>
          <Link to={`/brands${url}`}>
            <img src={logo} alt={name} />
            <h3>{name}</h3>
          </Link>
        </li>
      );
    });
  };

  const drawBrands2 = brands => {
    return brands.map(brand => {
      const { external, logo, name, url } = brand;
      return external ? (
        <li key={name}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <div className="background">{userIcon(name)}</div>
            <h3>{name}</h3>
          </a>
        </li>
      ) : null;
    });
  };

  return (
    <MaterialsBody>
      <h2>Find Your Marketing Materials</h2>
      <div className="branding">{/* <ul>{drawBrands(materials)}</ul> */}</div>
      <ul className="social">{drawBrands2(materials)}</ul>
    </MaterialsBody>
  );
};

export default Materials;
