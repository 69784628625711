import React from 'react';
import styled from 'styled-components/macro';
import { StaticQuery, graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';

// import helpers
import { getColor, getFontWeight } from '../util/theme';

const AnnouncementsBody = styled.div`
  background-color: #f7f7f7;

  margin: 0 25px 0 0;
  padding: 20px 30px 50px 30px;

  flex-basis: 60%;

  h2 {
    padding: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px dotted lightgrey;

      display: flex;
      flex-wrap: nowrap;

      font-size: 1.6rem;
      line-height: 1.3;

      padding: 10px 0;

      &:last-of-type {
        border-bottom: none;
      }

      span {
        display: inline-block;

        &.date {
          color: ${getColor('primaryBlue')};
          font-weight: 700;

          margin: 0 15px 0 0;
          text-align: right;
          width: 65px;
        }

        &.content {
          color: ${getColor('black')};
          width: calc(100% - 65px);

          a {
            color: ${getColor('primaryBlue')};
            font-weight: ${getFontWeight('medium')};

            &:hover {
              color: ${getColor('accentGreen')};
            }
          }
        }
      }
    }
  }
`;

const Announcements = () => {
  const drawAnnouncements = announcements => {
    return announcements.map(announcement => {
      const {
        node: { content, date, id },
      } = announcement;
      return (
        <li key={id}>
          <span className="date">{date}:</span>
          <span className="content">{ReactHtmlParser(content)}</span>
        </li>
      );
    });
  };

  return (
    <StaticQuery
      query={graphql`
        query GET_ANNOUNCEMENTS {
          allAnnouncementsJson(limit: 3) {
            edges {
              node {
                content
                date
                id
              }
            }
          }
        }
      `}
      render={data => {
        const {
          allAnnouncementsJson: { edges },
        } = data;

        return (
          <AnnouncementsBody>
            <h2>Announcements</h2>
            <ul>{drawAnnouncements(edges)}</ul>
          </AnnouncementsBody>
        );
      }}
    />
  );
};

export default Announcements;
