import React from 'react';
import styled from 'styled-components/macro';

// import components
import { Grid } from '../components/Grid';
import Layout from '../components/Layout';
import Materials from '../components/Materials';
import Announcements from '../components/Announcements';
import Facebook from '../components/Facebook';

// import helpers
import { getBreakpoint } from '../util/theme';

const BottomWrapper = styled.div`
  display: flex;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${getBreakpoint('lg')}) {
      grid-template-columns: 5fr 4fr;
      grid-column-gap: 25px;
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Grid>
      <Materials />
    </Grid>
  </Layout>
);

export default IndexPage;
