// import assets
import calconLogo from '../images/logos/calcon-logo.png';
import teamOneLogo from '../images/logos/teamone-logo.png';
import othlLogo from '../images/logos/othl-logo.png';
import taborLogo from '../images/logos/tabor-logo.png';
import emailIcon from '../images/icons/2.png';
import socialIcon from '../images/icons/3.png';
import flyerIcon from '../images/icons/tester4.png';
import personIcon from '../images/icons/1.png';

const materials = [
  {
    name: 'OneTrust Home Loans',
    logo: othlLogo,
    url: '/othl',
    external: false,
  },
  {
    name: 'CalCon Mutual Mortgage',
    logo: calconLogo,
    url: '/calcon',
    external: false,
  },
  {
    name: 'Tabor Mortgage Group',
    logo: taborLogo,
    url: '/tabor',
    external: false,
  },
  {
    name: 'Team One Home Loans',
    logo: teamOneLogo,
    url: '/teamone',
    external: false,
  },
  {
    name: 'Flyer Generator and Emails',
    logo: flyerIcon,
    url: 'https://onetrustmarketing.com/flyer-generator/decision',
    external: true,
  },

  // {
  //   name: 'Email Campaigns',
  //   logo: emailIcon,
  //   url: 'https://onetrustmarketing.com/lxercmbowpozmwbdgxky/',
  //   external: true,
  // },
  {
    name: 'Social Media Wall',
    logo: emailIcon,
    url: 'https://onetrustmarketing.com/social-wall/',
    external: true,
  },
  {
    name: 'Reverse Custom Collateral',
    logo: personIcon,
    url:
      'https://onetrusthomeloans.sharepoint.com/:f:/r/subsite/OneTrust%20University/OneTrust%20Marketing/Asset%20Management/Audience%20-%20Consumers/OTHL/Reverse?csf=1&web=1',
    external: true,
  },
  {
    name: 'SOCIAL MEDIA COLLATERAL',
    logo: socialIcon,
    url:
      'https://onetrusthomeloans.sharepoint.com/subsite/OneTrust%20University/Forms/AllItems.aspx?RootFolder=%2Fsubsite%2FOneTrust%20University%2FOneTrust%20Marketing%2FMarketing%20Material%2FSocial%20Media%20Calendar&FolderCTID=0x0120001F88585EC596F04A996BCEFDB15ECF35&View=%7B6E7281C3-9DA0-4F5F-B774-364A1B1691DB%7D',
    external: true,
  },
];

export default materials;
